$theme-colors: (
    "primary": #680da9,
    "secondary": #646464,
    "light": #e9ecef,
    "danger": #dc3545
);

@import "~bootstrap/scss/bootstrap";

// --- Perso bootstrap ---

.form-check-input:checked {
    background-color: #680da9;
    border-color: #680da9;
}

.form-check-input:focus {
    border-color: #cf8bff;
    box-shadow: 0 0 0 0.25rem rgba(137, 13, 253, 0.25);
}

// --- Perso swiper ---

.swiper-button-next:hover {
    transform: translateX(10px);
}
.swiper-button-prev:hover {
    transform: translateX(-10px);
}
.swiper-button-next, .swiper-button-prev {
    transition: all 0.5s;
}
.swiper-pagination-bullet {
    background: #680da9 !important;
}
.swiper-pagination {
    bottom: 10% !important;
}

// ------------------------

nav, footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 6vh;
    background: grey;
    color: white;
}

nav li {
    list-style: none;
}

footer {
    justify-content: space-around;
}

.nav-links {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    margin: 0;  
}

a {
    text-decoration: none;
    color: white;
    padding: 22px;
}

a:hover {
    background: #680da9;
    color: white;
}

.logo{
    margin: 0;
}

.offscreen {
    display: none;
}

.admin-main > div {
    padding-bottom: 6vh;
}

.loader.show {
    display: flex;
}

.loader {
    display: none;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 500;
    background: rgba(0,0,0,0.2);
    justify-content: center;
}


// --- Site Principal ---

@font-face {
    font-family: 'montserratEL';
    src: url('../src/polices/Montserrat-ExtraLight.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'montserratL';
    src: url('../src/polices/Montserrat-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'montserrat';
    src: url('../src/polices/Montserrat-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'nautigal';
    src: url('../src/polices/TheNautigal-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'nautigal';
    src: url('../src/polices/TheNautigal-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'fontAwesome';
    src: url('../src/polices/fa-solid-900.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'fontAwesomeRS';
    src: url('../src/polices/fa-brands-400.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

/* ------------- */
/*   Animations  */
/* ------------- */

@-webkit-keyframes inM{
50%{-webkit-transform:rotate(0deg);}
100%{-webkit-transform:rotate(45deg);}
}
@keyframes inM{
50%{transform:rotate(0deg);}
100%{transform:rotate(45deg);}
}
@-webkit-keyframes outM{
50%{-webkit-transform:rotate(0deg);}
100%{-webkit-transform:rotate(45deg);}
}
@keyframes outM{
50%{transform:rotate(0deg);}
100%{transform:rotate(45deg);}
}
@-webkit-keyframes inT{
0%{-webkit-transform: translateY(0px) rotate(0deg);}
50%{-webkit-transform: translateY(9px) rotate(0deg);}
100%{-webkit-transform: translateY(9px) rotate(135deg);}
}
@keyframes inT{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(9px) rotate(0deg);}
100%{transform: translateY(9px) rotate(135deg);}
}
@-webkit-keyframes outT{
0%{-webkit-transform: translateY(0px) rotate(0deg);}
50%{-webkit-transform: translateY(9px) rotate(0deg);}
100%{-webkit-transform: translateY(9px) rotate(135deg);}
}
@keyframes outT{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(9px) rotate(0deg);}
100%{transform: translateY(9px) rotate(135deg);}
}
@-webkit-keyframes inBtm{
0%{-webkit-transform: translateY(0px) rotate(0deg);}
50%{-webkit-transform: translateY(-9px) rotate(0deg);}
100%{-webkit-transform: translateY(-9px) rotate(135deg);}
}
@keyframes inBtm{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(-9px) rotate(0deg);}
100%{transform: translateY(-9px) rotate(135deg);}
}
@-webkit-keyframes outBtm{
0%{-webkit-transform: translateY(0px) rotate(0deg);}
50%{-webkit-transform: translateY(-9px) rotate(0deg);}
100%{-webkit-transform: translateY(-9px) rotate(135deg);}
}
@keyframes outBtm{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(-9px) rotate(0deg);}
100%{transform: translateY(-9px) rotate(135deg);}
}

/* ------------- */
/*     Popin     */
/* ------------- */

.modal {
    display: flex;
    justify-content: center;
    z-index: -1000;
    opacity: 0;
    transition: all 0.3s;
}
.modal.open {
    background: rgba(0,0,0,0.3);
    opacity: 100;
    z-index: 1000;
}
.modal-dialog, .modal-dialog-centered {
    min-width: 50%;
}
.modal-content {
    transform: scale(0);
    transition: all 0.3s;
}
.modal-content.open {
    transform: scale(1);
}

span {
    color: #680da9;
}

// --- ScrollBar ---

::-webkit-scrollbar {
    width: 4px;
    background-color: #c1c1c1;
}

::-webkit-scrollbar-thumb {
    background-color: #680da9;
}

#headerScroll {
    height: 50px;
    background-color: rgba(128, 128, 128);
}

// --- Slide 1 ---

.menu-nav {
    display: none;
}

#Slide1 header {
    height: 50px;
    background-color: rgba(128, 128, 128, 0.5);
}
#Slide1 {
    height: 100vh;
}

#Slide1 #presentation {
    height: calc(100vh - 50px);
    position: relative;
    font-family: 'montserratL';
    text-shadow: 0px 0px 6px #777;
}
#Slide1 #presentation h1, #Slide1 #presentation .slogan{
    font-family: 'nautigal';
    font-weight: bold;
}
#Slide1 #presentation .titre {
    position: absolute;
    top: 10%;
    left: 10%;
}
#Slide1 #presentation .slogan {
    position: absolute;
    bottom: 22%;
    right: 7%;
    font-size: 2.7vw;
}
#Slide1 #presentation h1 {
    font-size: 7vw;
}
#Slide1 #presentation h2 {
    font-family: 'nautigal';
    font-weight: normal;
    font-size: 3.5vw;
    position: absolute;
    top: 6vw;
    left: 12vw;
}
#Slide1 #presentation p {
    text-align: center;
    font-size: 1.5em;
    margin-bottom: 0.7vw;
}
#Slide1 #presentation .description {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    height: 100%;
}
.mouse-scroll {
    background: url(./images/mouse.png) no-repeat center;
    position: absolute;
    bottom: 0;
    left: 50vw;
    height: 35px;
    width: 42px;
    margin-bottom: 35px;
    background-size: 100%;
}
.mouse-scroll:before {
    content: '';
    height: 33px;
    width: 2px;
    background: black;
    display: block;
    position: absolute;
    bottom: -35px;
    left: 20px;
}

#Slide2, #Slide3, #Slide4, #Slide5, #Slide6 {
    height: 100vh;
    padding-top: 50px;
    position: relative;
}

#Slide1 .imagebg {
    background: url(../src/images/bg_accueil.jpg) no-repeat center; 
}
#Slide2 .imagebg {
    background: url(../src/images/BG1.png) no-repeat center; 
}
#Slide3 .imagebg {
    background: url(../src/images/BG2.png) no-repeat center; 
}
#Slide4 .imagebg {
    background: url(../src/images/BG3.png) no-repeat center; 
}
#Slide5 .imagebg {
    background: url(../src/images/BG4.png) no-repeat center; 
}
#Slide6 .imagebg {
    background: url(../src/images/BG5.png) no-repeat center; 
}
#Slide7 .imagebg {
    background: url(../src/images/BG6.png) no-repeat center; 
}
.imagebg {
    background-size: cover !important;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: -1;
}
.imagebg::before {
    content: '';
    background: #e2e2e2;
    height: 2px;
    width: 100%;
    display: block;
    top: -2px;
    position: absolute;
}
#Slide1 .imagebg::before, #Slide2 .imagebg::before, #Slide7 .imagebg::before {
    display: none;
}

#Slide1 nav, #headerScroll nav {
    justify-content: center;
    background: none;
    min-height: 0;
    height: 100%;
}
#Slide1 ul, #headerScroll ul {
    display: flex;
    padding: 0;
    margin: 0;
}
#Slide1 li, #headerScroll li {
    display: flex;
}
#Slide1 ul a, #headerScroll ul a {
    padding: 12px;
    text-transform: uppercase;
    font-size: 12px;
    font-family: 'montserrat';
    transition:all ease 0.5s;
}
#Slide1 ul a:hover, #headerScroll ul a:hover {
    background: none;
    color: #680da9;
}

#headerScroll {
    position: fixed;
    width: 100%;
    z-index: 100;
    transition: all ease 0.3s;
}
#headerScroll.invisible {
    opacity: 0;
    top: -50px;
}
#headerScroll.visible {
    opacity: 1;
    top: 0px;
}

// --- Slide 2 ---

#Slide2 h1 {
    position: absolute;
    top: 16%;
    left: 8%;
    font-size: 7vw;
    font-family: "nautigal";
    font-weight: bold;
    text-shadow: 0px 0px 6px #777;
}
#Slide2 h1 span {
    position: absolute;
    top: -4.5vw;
    left: -3.5vw;
}

.swiper {
    height: 100%;
}

.swiper-button-next, .swiper-button-prev {
    color: #680da9;
}
.swiper-button-next {
    right: 8vw;
}
.swiper-button-prev {
    left: 8vw;
}
.swiper-slide > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}
.swiper-slide h2 {
    font-size: 4.5em;
    font-family: "nautigal";
    font-weight: bold;
    margin-top: 2vw;
    margin-bottom: 0;
}
#Slide2 .swiper-pagination {
    display: none;
}

.swiper-pagination-bullet {
    margin-bottom: 7px;
}

#infoPrix {
    display: flex;
    width: 40%;
    min-width: 330px;
    justify-content: flex-end;
    padding: 1.7vw 0.5vw;
}

#infoPrix div {
    margin-left: 2.2vw;
    margin-right: 1vw;
    font-size: 1.2em;
    font-family: 'montserratL';
    color: #680da9;
    font-style: italic;
}

.swiper-slide ul {
    display: flex;
    list-style: none;
    padding: 0 5px;
    margin: 0;
    width: 40%;
    min-width: 350px;
    font-size: 0.9rem;
    font-family: "montserrat";
    height: 55%;
    flex-direction: column;
    overflow-y: auto;
}
.swiper-slide ul ul{
    min-width: 0;
}
.swiper-slide li {
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-bottom: 0.3vw;
}
.swiper-slide li.formules {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    border: 1px solid #c1c1c1;
    padding: 3px 6px;
    background-color: #f8f8f8;
}
.prix {
    display: flex;
}
.prix div {
    margin-left: 2.5vw;
}
.swiper-slide li.produits:after {
    content: "";
    height: 2px;
    width: 7vw;
    background-color: gainsboro;
    position: absolute;
    top: 50%;
    left: 50%;
}
.swiper-slide li > div:first-child {
    display: flex;
    align-items: center;
}
.swiper-slide li.formules > div:first-child {
    justify-content: space-between;
}
.swiper-slide li.formules > ul {
    font-family: "montserratL";
    height: inherit;
    width: inherit;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    font-style: italic;
    padding: 0;
}
.swiper-slide li.formules ul li {
    font-size: 0.9rem;
    margin: 0 4px;
}
.swiper-slide h3 {
    font-size: 1rem;
    margin: 0;
}
.swiper-slide li span {
    font-style: italic;
    font-family: "montserratL";
    font-size: 1vw;
    margin-left: 0.4vw;
    background: white;
    z-index: 10;
    padding-right: 10px;
}
.swiper-slide .description {
    margin-top: 1vh;
    font-family: "montserratL";
    font-size: 1rem;
    font-style: italic;
    width: inherit;
    height: auto;
    overflow: inherit;
}

.swiper-slide .description li {
    justify-content: center;
}

.noProduit {
    font-size: 1.3vw;
    font-family: "montserrat";
    margin-top: 5%;
}

// --- Slide 3 ---

#Slide3 h1 {
    position: absolute;
    top: 10%;
    right: 5%;
    font-size: 7vw;
    font-family: "nautigal";
    font-weight: bold;
    text-shadow: 0px 0px 6px #777;
    z-index: 2;
}
#Slide3 .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
}
#Slide3 .swiper-slide div:first-child {
    position: relative;
    height: auto;
}
#Slide3 .swiper-slide h2 {
    position: absolute;
    top: -35px;
    font-size: 30px;
    left: 0;
    margin: 0;
    z-index: 10;
}
#Slide3 .swiper-slide div > div {
    padding: 0px;
    margin: 0px auto;
    width: 70vw;
    height: auto;
    max-height: 45vw;
    position: relative;
    overflow: hidden;
    border: 1px solid #000;
    box-shadow: 0px 0px 10px #bbb;
    justify-content: center;
}
#Slide3 img {
    width: 100%;
}

// --- Slide 4 ---

#Slide4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}
#Slide4 h1 {
    position: absolute;
    top: 17%;
    left: 10%;
    font-size: 7vw;
    font-family: "nautigal";
    font-weight: bold;
    text-shadow: 0px 0px 6px #777;
}
#Slide4 h1 span {
    position: absolute;
    top: -5.5vw;
    left: -6.5vw;
}
#Slide4 .rdv{
    font-size: 2.5rem;
    font-family: "nautigal";
}
#Slide4 > div {
    text-align: center;
    font-size: 1.8rem;
    font-family: "nautigal";
}
#Slide4 > div > div {
    margin-bottom: -6px;
}
#Slide4 .explications {
    width: 60%;
    text-align: center;
    font-size: 1.1rem;
    font-family: "montserrat";
}
#Slide4 .explications a {
    color: #680da9;
    padding: 0;
}
#Slide4 .explications a:hover {
    color: grey !important;
    background: none;
}
#Slide4 input ~ span {
    margin-top: -5px;
}

// --- formulaire ---

#Slide4 form {
    padding: 0px;
    margin: 0px auto;
    width: 800px;
    height: 245px;
    position: relative;
    display: flex;
    justify-content: space-between;
}
#Slide4 form label {
    display: none;
}
#Slide4 form input {
    height: 40px;
    background-color: #fff;
    border: 1px solid #000;
    font-family: "montserratL";
    font-size: 22px;
    color: #000;
    outline: none;
    padding-left: 5px;
}
#Slide4 form > div {
    display: flex;
    width: 48%;
    flex-direction: column;
    justify-content: space-between;
}
form #texte
{
    height: 100%;
	background-color: #fff;
	color: #000;
	font-family: "montserratL";
	font-size: 22px;	
	border: 1px solid #000;
	overflow: auto;
	outline: none;
	resize: none;
	padding-left: 5px;
}
form #envoyer
{
    position: absolute;
    width: 384px;
    bottom: -53px;
    right: 0;
	background-color: #eee !important;
	color: #000;
	font-size: 22px;
	letter-spacing:  1px;
	font-weight : normal;
	font-family: "montserratL";
	padding-top: 2px;
	cursor:  pointer;
}
form #envoyer:hover
{
	background-color: #000 !important;
	color: #fff;
}

// --- Slide 5 ---

#Slide5 {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
#Slide5 h1 {
    position: absolute;
    top: 17%;
    right: 5%;
    font-size: 7vw;
    font-family: "nautigal";
    font-weight: bold;
    text-shadow: 0px 0px 6px #777;
}
#Slide5 h1 span {
    position: absolute;
    top: -5.2vw;
    left: -3.5vw;
    color: #680da9;
}
#Slide5 aside {
    width: 20%;
    height: 55%;
    margin-top: 10%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
#Slide5 aside p {
    display: flex;
    flex-direction: column;
}
#Slide5 aside p span {
    font-weight: bold;
}
#Slide5 > div:nth-of-type(2) {
    width: 60vw;
    height: 40vw;
    margin-left: 5%;
}
#Slide5 .photos {
    height: 45%;
    display: flex;
}
#Slide5 .photos div {
    margin: 0.5%;
    display: flex;
    justify-content: center;
    align-items: center;
}
#Slide5 .photos .photo1 {
    background: url(../src/images/background2.jpg) 93% 67%; 
    flex-grow: 1;
    background-size: 255%;
}
#Slide5 .photos .photo2 {
    flex-grow: 2;
    background: url(../src/images/background6.jpg) 70% 0%; 
    background-size: 100%;
}
#Slide5 iframe {
    height: 53%;
    margin: 0.5%;
    width: 99%;
}

// --- Slide 6 ---

#Slide6 {
    height: inherit;
}
#Slide6 h1 {
    position: absolute;
    top: 14%;
    left: 8%;
    font-size: 7vw;
    font-family: "nautigal";
    font-weight: bold;
    text-shadow: 0px 0px 6px #777;
}
#Slide6 h1 span {
    position: absolute;
    top: -4.8vw;
    left: -4vw;
}
#Slide6 h3 {
    font-family: 'montserrat';
    font-size: 1.4rem;
    margin-bottom: -2px;
}
#Slide6 h3 span {
    font-weight: bold;
    position: relative;
}
#Slide6 h3 span:before {
    content: '\f621';
    font-family: 'fontAwesome';
    color: black;
    font-size: 0.5vw;
    position: absolute;
    left: -12px;
    top: 4px;
}
#Slide6 > p {
    padding-top: 2%;
}
#Slide6 p {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'montserratL';
    font-size: 1.1rem;
}
#Slide6 p span {
    color: inherit;
    margin-bottom: -5px;
}
#Slide6 ul {
    margin-top: 3%;
    overflow-y: auto;
    height: 76%;
}
#Slide6 ul li {
    list-style: none;
    text-align: center;
    margin-bottom: 2%;
}

// --- Slide 7 ---

#Slide7 {
    height: 28vh;
    position: relative;
}
#Slide7 .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
}
#Slide7 .temoignage {
    width: 35%;
    height: 70%;
    box-shadow: 0px 0px 11px 2px rgb(0 0 0 / 20%);
    position: relative;
    margin-top: 3%;
    justify-content: space-evenly;
    background: white;
}
#Slide7 .photo-temoignage {
    position: absolute;
    top: -55px;
    background: grey;
    height: 75px;
    width: 75px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
}
#Slide7 img {
    display: inline;
    margin: 0 auto;
    height: 100%;
    width: auto;
}
#Slide7 .photo-temoignage > div {
    text-align: center;
    font-size: 38px;
    font-weight: bold;
    font-family: 'montserrat';
    color: white;
    padding-top: 12px;
    padding-left: 4px;
}
#Slide7 .nom-temoignage {
    font-family: 'nautigal';
    font-size: 35px;
    font-weight: bold;
    z-index: 2;
    padding-top: 14px;
}
#Slide7 .texte-temoignage {
    font-family: 'montserratL';
    font-size: 14px;
    padding-bottom: 12px;
    padding-right: 10px;
    padding-left: 10px;
    text-align: center;
    overflow-y: auto;
}
#Slide7 .swiper-button-prev {
    left: 25vw;
    top: 60%;
    color: black;
}
#Slide7 .swiper-button-next {
    right: 25vw;
    top: 60%;
    color: black;
}

// --- Slide 8 ---

#Slide8 {
    height: 18vh;
    background-color: #e2e2e2;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
#Slide8 > div {
    text-align: center;
}
#Slide8 .adresse > div:first-child {
    font-family: 'nautigal';
    font-size: 28px;
}
#Slide8 .adresse > div:nth-child(2){
    font-family: 'montserratL';
    font-size: 14px;
}
#Slide8 .tel {
    font-family: 'nautigal';
    font-size: 28px;
}
#Slide8 .reseaux-sociaux {
    display: flex;
    width: 20%;
    justify-content: space-between;
    color: #898989;
}
#Slide8 .mail, #Slide8 .facebook, #Slide8 .coeur {
    font-family: 'fontAwesome';
    font-size: 28px;
    border: #898989 solid;
    border-radius: 50%;
    height: 45px;
    width: 45px;
    position: relative;
    transition: all 0.7s;
    cursor: pointer;
    color: inherit;
    padding: 0;
}
#Slide8 .facebook {
    font-family: 'fontAwesomeRS';
}
#Slide8 .mail::before {
    content: '\f1d8';
    position: absolute;
    left: 12%;
}
#Slide8 .facebook::before {
    content: '\f39e';
}
#Slide8 .coeur::before {
    content: '\f004';
    position: absolute;
    left: 16%;
    top: 2%;
}
.coeur:hover, .facebook:hover, .mail:hover {
    background: none;
    transform: scale(1.2);
    border-color: #680da9 !important;
    color: #680da9 !important;
}

// --- Footer ---

footer {
    font-family: 'montserratL';
    letter-spacing: 0.1vw;
    font-size: 0.8rem;
}

.footer-admin {
    font-size: inherit;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
}

.bouton-classement {
    cursor: pointer;
}


// --- GRANDS ECRANS ---

@media (min-width: 1700px) {

    // --- Slide2 ---
    .swiper-slide ul {
        font-size: 1.1rem;
    }
    .swiper-slide h3 {
        font-size: 1.2rem;
    }
    .swiper-slide li.formules ul li {
        font-size: 1rem;
    }
    .swiper-slide .description {
        font-size: 1.2rem;
    }
    
}

// --- SMARTPHONES PAYSAGE & TABLETTES ---

@media (min-width: 576px) and (max-width: 991.98px) {
    // --- Général ---
    #headerScroll, .mouse-scroll {
        display: none;
    }
    .swiper-button-next:hover, .swiper-button-prev:hover {
        transform: none;
    }

    #Slide2, #Slide3, #Slide4, #Slide5, #Slide6 {
        padding-top: inherit;
    }

    #Slide2 h1, #Slide3 h1 {
        top: 5%;
    }

    // --- Slide4 ---

    #Slide4 h1 {
        top: 10%;
        left: 6%;
        font-size: 5vw;
    }
    #Slide4 h1 span {
        top: -4.5vw;
        left: -4.5vw;
    }
    #Slide4 .explications {
        padding-left: 50%;
        width: 90%;
        margin-bottom: 0;
    }
    #Slide4 .rdv, #Slide4 .tel {
        display: none;
    }
    #Slide4 form {
        height: 225px;
        width: 95%;
        margin-bottom: 50px;
    }
    #Slide4 form #envoyer {
        width: 48%;
    }

    // --- Slide5 ---

    #Slide5 h1 {
        top: 13%;
        right: 4%;
    }
    #Slide5 .photos {
        display: none;
    }
    #Slide5 > div:nth-of-type(2) {
        order: 2;
        height: 80%;
    }
    #Slide5 iframe {
        height: 100%;
    }
    #Slide5 aside {
        margin: 0;
        height: inherit;
    }
    #Slide5 aside p {
        font-size: 1.5vw;
    }

    // ---Slide7 ---

    #Slide7 {
        height: 60vh;
    }
    #Slide7 .swiper-button-prev {
        left: 7vw;
    }
    #Slide7 .swiper-button-next {
        right: 7vw;
    }
    #Slide7 .temoignage {
        width: 70%;
    }
    #Slide7 .photo-temoignage {
        height: 60px;
        width: 60px;
        top: -40px;
    }
    #Slide7 .photo-temoignage > div {
        padding-top: 4px;
    }

    // ---Slide8 ---

    #Slide8 {
        height: 37vh;
    }
}

// --- SMARTPHONES ---

@media (max-width: 575.98px) {

    // --- Général ---

    #headerScroll, .mouse-scroll {
        display: none;
    }
    .swiper-button-next:hover, .swiper-button-prev:hover {
        transform: none;
    }

    #Slide2, #Slide3, #Slide4, #Slide5, #Slide6 {
        padding-top: inherit;
    }
    .imagebg::before {
        top: inherit;
    }
    #Slide2 .imagebg {
        background: url(../src/images/BG1_smartphone.png) no-repeat center; 
    }
    #Slide3 .imagebg {
        background: url(../src/images/BG2_smartphone.png) no-repeat center; 
    }
    #Slide4 .imagebg {
        background: url(../src/images/BG3_smartphone.png) no-repeat center; 
    }
    #Slide5 .imagebg {
        background: url(../src/images/BG4_smartphone.png) no-repeat center; 
    }

    // --- Slide1 ---

    .menu-nav, #menu-nav-clic {
        display: block;
        cursor: pointer;
        width: 48px;
        height: 48px;
        position: absolute;
        right: 0;
        padding: 12px 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
    #menu-nav-clic{
        z-index: 100;
    }
    #Slide1 li:first-child a {
        display: none;
    }
    i {
        background-color: black;
        border-radius: 2px;
        content: '';
        display: block;
        width: 100%;
        height: 4px;
        transition: all ease 0.3s;
    }
    i:nth-child(1) {
        -webkit-animation: outT 0.8s backwards;
        animation: outT 0.8s backwards;
        -webkit-animation-direction: reverse;
        animation-direction: reverse;
    }
    .active i:nth-child(1) {
        -webkit-animation: inT 0.8s forwards;
        animation: inT 0.8s forwards;
    }
    i:nth-child(2) {
        margin: 5px 0;
        -webkit-animation: outM 0.8s backwards;
        animation: outM 0.8s backwards;
        -webkit-animation-direction: reverse;
        animation-direction: reverse;
    }
    .active i:nth-child(2) {
        -webkit-animation: inM 0.8s forwards;
        animation: inM 0.8s forwards;
    }
    i:nth-child(3) {
        -webkit-animation: outBtm 0.8s backwards;
        animation: outBtm 0.8s backwards;
        -webkit-animation-direction: reverse;
        animation-direction: reverse;
    }
    .active i:nth-child(3) {
        -webkit-animation: inBtm 0.8s forwards;
        animation: inBtm 0.8s forwards;
    }


    #Slide1 header {
        height: auto;
    }
    #Slide1 nav {
        line-height: 50px;
        text-align: center;
        display: block;
    }
    #Slide1 ul {
        display: block;
    }
    #Slide1 ul a {
        opacity: 0;
    }
    #Slide1 ul a.active {
        opacity: 100;
    }
    #Slide1 li {
        display: block;
        border-bottom: 0px solid rgba(0,0,0,0);
        height: 0;
        transition: all 0.5s;

    }
    #Slide1 li.active {
        height: 48px;
        border-bottom: 1px solid #000;
    }
    #Slide1 #presentation {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        transition: all 0.5s;
    }
    #Slide1 #presentation.active {
        height: calc(100vh - 322px);
    }
    #Slide1 #presentation .titre {
        position: relative;
        display: flex;
        justify-content: center;
        top: inherit;
        left: inherit;
    }
    #Slide1 #presentation h1 {
        font-size: 12vw;
    }
    #Slide1 #presentation h2 {
        font-size: 6vw;
        top: 11vw;
        left: 28vw;
    }
    #Slide1 #presentation .slogan {
        position: relative;
        bottom: inherit;
        right: inherit;
        font-size: 6vw;
        text-align: center;
    }
    #Slide1 #presentation p {
        font-size: 4vw;
        margin-bottom: 1rem;
    }
    #Slide1 #presentation .description {
        height: auto;
    }

    // --- Slide2 ---

    #Slide2 h1 {
        top: 4%;
        left: 8%;
        font-size: 10vw;
    }
    #Slide2 h1 span {
        top: -7.5vw;
        left: -5.5vw;
    }
    #Slide2 .swiper-button-prev {
        left: 2vw;
        top: 93%;
        z-index: 15;
    }
    #Slide2 .swiper-button-next {
        right: 2vw;
        top:93%;
        z-index: 15;
    }
    #Slide2 .swiper-slide > div {
        padding-top: 25%;
    }
    #Slide2 .swiper-slide h2 {
        font-size: 15vw;
        margin-top: inherit;
        position: absolute;
        top: 1%;
        right: 7%;
    }
    #Slide2 .swiper-slide h3 {
        font-size: 3.5vw;
    }
    #Slide2 .swiper-slide div > ul:first-of-type {
        width: 90%;
        font-size: 3.8vw;
    }
    #Slide2 .swiper-slide li.formules ul li {
        font-size: 2.5vw;
    }
    #Slide2 .swiper-slide li span {
        font-size: 2.5vw;
    }
    #Slide2 .swiper-slide li.produits:after {
        display: none;
    }
    #Slide2 .swiper-slide .description {
        width: 80%;
        text-align: center;
        font-size: 3vw;
    }
    #Slide2 .swiper-slide .description li {
        margin-bottom: 2vw;
    }
    #Slide2 .swiper-pagination {
        display: block;
        bottom: 5% !important;
        padding: 0 10vw;
    } 
    #infoPrix {
        width: 90%;
        padding: 3.5vw;
        padding-left: 0;
    }
    #infoPrix div {
        margin-left: 5.5vw;
        font-size: 5.2vw;
    }

    // --- Slide3 ---

    #Slide3 h1 {
        font-size: 25vw;
        top: inherit;
        right: inherit;
        width: 100%;
        text-align: center;
        padding-top: 5%;
    }
    #Slide3 .swiper-button-prev {
        left: 2vw;
        top: 80%;
        z-index: 15;
    }
    #Slide3 .swiper-button-next {
        right: 2vw;
        top: 80%;
        z-index: 15;
    }
    #Slide3 .swiper-pagination {
        bottom: 16% !important;
        padding: 0 10vw;
    }
    #Slide3 .swiper-slide div > div {
        width: 95vw;
        height: auto;
        max-height: 60vw;
    }

    // --- Slide4 ---

    #Slide4 h1 {
        top: 5%;
        left: 10%;
        font-size: 10vw;
    }
    #Slide4 h1 span {
        top: -8.5vw;
    }
    #Slide4 .explications {
        font-size: 2.2vw;
        width: 90%;
        margin-bottom: -15px;
    }
    #Slide4 > div {
        font-size: 6vw;
    }
    #Slide4 .rdv {
        font-size: 6.5vw;
        padding-left: 35%;
        width: 85%;
        text-align: center;
    }
    #Slide4 form {
        margin-bottom: 50px;
        width: 95%;
        height: 450px;
        flex-direction: column;
    }
    #Slide4 form > div {
        width: 100%;
        height: 65%;
    }
    #Slide4 form > div:last-of-type {
        height: 130px;
    }
    form #envoyer {
        width: 100% !important;
    }
    form #texte {
        width: 100%;
    }


    // --- Slide5 ---

    #Slide5 {
        flex-direction: column;
    }
    #Slide5 h1 {
        top: 4%;
        font-size: 10vw;
    }
    #Slide5 h1 span {
        top: -8.2vw;
    }
    #Slide5 .photos {
        display: none;
    }
    #Slide5 > div:nth-of-type(2) {
        margin: 0;
        width: 95%;
        height: 50%;
    }
    #Slide5 iframe {
        height: 100%;
    }
    #Slide5 aside {
        width: 95%;
        height: 30%;
        margin: 0;
    }
    #Slide5 aside p {
        font-size: 3.5vw;
    }

    // --- Slide6 ---

    #Slide6 {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    #Slide6 h1 {
        top: 4%;
        font-size: 10vw;
    }
    #Slide6 h1 span {
        top: -7.5vw;
    }
    #Slide6 p {
        font-size: 3vw;
    }
    #Slide6 > p {
        padding-left: 40%;
        text-align: center;
    }
    #Slide6 p span {
        margin: 0;
    }
    #Slide6 h3 {
        font-size: 4vw;
    }
    #Slide6 h3 span:before {
        font-size: 1.5vw;
    }
    #Slide6 ul {
        padding: 0;
        width: 95%;
    }

    // --- Slide7 ---
    #Slide7 {
        height: 45vh;
    }

    #Slide7 .swiper-button-prev {
        left: 5vw;
        top: 25%;
    }
    #Slide7 .swiper-button-next {
        right: 5vw;
        top: 25%;
    }
    #Slide7 .temoignage {
        width: 95%;
        height: 80%;
        margin-top: 10%;
    }
    #Slide7 .photo-temoignage {
        height: 60px;
        width: 60px;
        top: -40px;
    }
    #Slide7 .photo-temoignage > div {
        font-size: 30px;
        padding-top: 9px;
    }

    // --- Slide8 ---

    #Slide8 {
        height: 40vh;
        flex-direction: column;
    }
    #Slide8 > div > div {
        margin-bottom: -7px;
    }
    #Slide8 .reseaux-sociaux {
        width: 100%;
        justify-content: space-evenly;
        order: 3;
    }

    // --- footer ---

    footer {
        font-size: 3vw;
        padding: 0 10%;
        text-align: center;
    }

    

}